var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              return _vm.setCheckedKeys(_vm.allArr)
            },
          },
        },
        [_vm._v("全选全部")]
      ),
      _c(
        "el-button",
        {
          on: {
            click: function ($event) {
              return _vm.setCheckedKeys(_vm.readOnly)
            },
          },
        },
        [_vm._v("全选只读")]
      ),
      _c("el-tree", {
        ref: "tree",
        staticStyle: { "margin-top": "20px" },
        attrs: {
          data: _vm.data3,
          "show-checkbox": "",
          "node-key": "id",
          "default-expand-all": "",
        },
        on: { "check-change": _vm.nodeClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }